import React, { useEffect, useState } from 'react'
import '../css/home.css'
import Excel from '../assets/excel.svg'
import Pdf from '../assets/pdf.svg'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import axios from 'axios'

export const Home = () => {
  const [docs, setDocs] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [tip, setTip] = useState(false)
  /* const currentYear = new Date().getFullYear() */

  const getDocs = () => {
    axios
      .get('https://data.ghg.ee/asset/documents')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setDocs(response.data)
          setTip(true)
          // eslint-disable-next-line no-console
          console.log(docs)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }

  /* useEffect(async () => {
    // Your API calls or other logic here
    getDocs();
  }, []); */

  useEffect(() => {
    // Your API calls or other logic here
    getDocs()
  }, [])

  return (
    <article className="home_article">
      <section className="section_left">
        <h2>Emission Factors Database</h2>
        <p>
          The emission conversion factors are for use by Taltech and other
          organizations to report on greenhouse gas emissions.
        </p>
        <div className="subheading">Documents</div>
        <div className="link-list">
          {docs.map((item) => (
            // eslint-disable-next-line react/jsx-key
            <a
              className="link-item"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img id="leftcol" src={Excel} alt="excel"/>
              <span id="rightcol">
                <strong>{item.title}</strong>
                {item.description}
              </span>
            </a>
          ))}
        </div>
        {docs.length !== 0
          ? (
            <footer className="footer_home">
              <div className="tip-wrapper">
                <span className="tip">Tip</span>Clink on the document to download{' '}
                {/*  <br/>
                &#10; Copyright &copy;{currentYear} ghg.ee All Rights Reserved */}
              </div>
            </footer>

            )
          : (
            <footer className="footer_home">
              <div className="tip-wrapper">
                Currently no document has been added{' '}
              </div>
            </footer>
            )}
      </section>

      <section className="section_right">
        <div className="title_updates">Related content</div>
        <Divider textAlign="left">
          {' '}
          <Chip label="Tools" size="small" variant="outlined" color="info"/>
        </Divider>
        <br/>

        <a
          className="link-item"
          href="https://ghg-tool.vercel.app/login?callbackUrl=https%3A%2F%2Fghg-tool.vercel.app%2F"
          target="_blank"
          rel="noreferrer"
        >
          <span>
            <strong>Corporate GHG Calculator </strong>
          </span>
        </a>
        <br/>
        <a
          className="link-item"
          href="https://taltech-ghg-app-backend.onrender.com/admin/auth/login"
          target="_blank"
          rel="noreferrer"
        >
          <span>
            <strong>GHG Content management</strong>
          </span>
        </a>

        {/* <br />
        <a
          className="link-item"
          href=""
          target="_blank"
          rel="noreferrer"
        >
          <span>
            <strong>GHG Building tool</strong>
          </span>
        </a> */}

        <br/>
        <Divider textAlign="left">
          <Chip label="Reports" size="small" variant="outlined" color="info"/>
        </Divider>
        <br/>

        <a className="link-item">
          {' '}
          <img src={Pdf} alt="pdf"/>
          <span className="left">
            <strong>Taltech GHG report 2022</strong>
          </span>
        </a>

        <br/>
        {/* <Pagination count={3} size="small" sx={{color:'primary.accent'}}  /> */}
        <Divider textAlign="left">
          <Chip
            label="Notifications"
            size="small"
            variant="outlined"
            color="info"
          />
        </Divider>
        <br/>

        <a className="link-item">
          {' '}
          <span className="left">Last updated on 2 August 2023</span>
        </a>
        <br/>
      </section>
    </article>

  )
}
