import React, { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import ListItemButton from '@mui/material/ListItemButton'
import Tooltip from '@mui/material/Tooltip'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import PostAddIcon from '@mui/icons-material/PostAdd'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography'
import { createTheme } from '@mui/material/styles'
import { Document } from './Document'
import { User } from './User'
import { Notification } from './Notification'
import { Overview } from './Overview'

const data = [
  { name: 'Overview', icon: <DashboardIcon/> },
  { name: 'Documents', icon: <PostAddIcon/> },
  { name: 'Users', icon: <PeopleIcon/> },
  { name: 'Notifications', icon: <NotificationAddIcon/> }
]

export const NavDrawer = () => {
  const [open, setOpen] = useState(false)
  const [dashboardName, setDashboardName] = useState('Overview')
  const [val, setVal] = useState(0)
  const theme = createTheme({
    palette: {
      primary: {
        main: '#E4067E'
      },
      secondary: {
        main: '#342B60',
        txt: '#fff'
      },
      accent: {
        main: '#E4067E'
      },
      bbox: {
        main: '#DADAE4',
        secondary: '#9396b0'
      }
    }
  })

  const handleMenuItemChange = (e, index, item) => {
    e.preventDefault()
    setVal(index)
    setDashboardName(item.name)
  }

  const getList = () => (
    <div style={{ width: 250 }} onClick={() => setOpen(false)}>
      {data.map((item, index) => (
        <ListItem key={index} sx={{ color: 'secondary.main' }}>
          <ListItemButton
            onClick={(e) => {
              handleMenuItemChange(e, index, item)
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name}/>
          </ListItemButton>
        </ListItem>
      ))}
    </div>
  )
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 0.5 }}>
        <Box>
          {/* <CssBaseline/> */}
          <AppBar
            /* position="static" */
            position="sticky"
            open={open}
            sx={{
              bgcolor: 'secondary.txt',
              color: 'secondary.main'
            }}
          >
            <Toolbar variant="dense">
              <Tooltip arrow placement="right" title="Menu">
                <MenuIcon
                  onClick={() => setOpen(true)}
                  sx={{ mr: 2, ...(open && { display: 'none' }) }}
                />
              </Tooltip>
              <Typography variant="h6" noWrap component="div">
                {dashboardName}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            open={open}
            anchor={'left'}
            onClose={() => setOpen(false)}
          >
            {getList()}
          </Drawer>
        </Box>
        <Box
          /* variant="scrollable"
                    visiblescrollbar={false} */
          sx={{
            padding: 2,
            backgroundColor: 'bbox.main',
            color: 'secondary.main',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
          }}
        >
          {val === 0 && (
            <Box>
              <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Overview/>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          )}
          {val === 1 && (
            <Box>
              <Container maxWidth=",lg" sx={{ mt: 1, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                    >
                      <Document/>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          )}
          {val === 2 && (
            <Box>
              <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                    >
                      <User/>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          )}
          {val === 3 && (
            <Box>
              <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                    >
                      <Notification/>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          )}
          {/* {val === 4 && (
            <Box visiblescrollbar={false}>
              <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <EmissionFactors />
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          )}
          {val === 5 && (
            <Box visiblescrollbar={false}>
              <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Exiobase />
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          )} */}
        </Box>
      </Box>
    </ThemeProvider>
  )
}
