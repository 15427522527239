import React from 'react'
import { AppRoutes } from './routes/AppRoutes'
import { Header } from './component/Header'
import { BrowserRouter as Router } from 'react-router-dom'
import PropTypes from 'prop-types'
import './css/App.css'
import { Footer } from './component/Footer'
import { PrimeReactProvider } from 'primereact/api'
import { MsalProvider } from '@azure/msal-react'

const MainContent = () => {
  return (
    <div className="App">
        <Router basename="/">
          <Header />
          <AppRoutes />
          <Footer />
        </Router>
    </div>
  )
}

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

const App = ({ instance }) => {
  return (
    <PrimeReactProvider>
      <MsalProvider instance={instance}>
        <MainContent />
      </MsalProvider>
    </PrimeReactProvider>
  )
}

export default App
App.propTypes = {
  instance: PropTypes.func.isRequired
}
