import React, { useEffect, useState } from 'react'
import Taltech from '../assets/taltech_white.png'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
  , useIsAuthenticated
} from '@azure/msal-react'
import { loginRequest } from '../authConfig'
import { Button } from './Button'
import '../css/header.css'
import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export const Header = () => {
  const isAuthenticated = useIsAuthenticated()
  const { instance } = useMsal()
  const { accounts } = useMsal()
  const user = accounts[0]
  const [allowedUsers, setAllowedUsers] = useState([])
  const getAllowedUsers = () => {
    axios
      .get('https://data.ghg.ee/asset/users')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setAllowedUsers(response.data)
          // eslint-disable-next-line no-console
          console.log(allowedUsers)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('There was an error!', error.message)
      })
  }
  useEffect(() => {
    // Your API calls or other logic here
    getAllowedUsers()
  }, [])

  const handleLoginRedirect = () => {
    // eslint-disable-next-line no-console
    instance.loginRedirect(loginRequest).catch((error) => console.log(error))
  }

  const handleLogoutRedirect = () => {
    // eslint-disable-next-line no-console
    instance.logoutRedirect().catch((error) => console.log(error))
  }

  const navigate = useNavigate()

  const navigateHome = () => {
    navigate('/', { replace: true })
  }

  return (
    <header>
      <div className="wrapper-white">
        <div className="div-green">
          <p className="p-green">EF database</p>
        </div>
        <div>

          <Typography sx={{ flexGrow: 1 }}>
            {isAuthenticated && allowedUsers.includes(user.username)
              ? <Link className="link_left" href="navdrawer" underline="hover">
                <b> {'Content management'}</b>
              </Link>
              : null
            }
          </Typography>
        </div>
      </div>

      <div className="wrapper">
        <div>
          <div id="pointer"></div>
          <img src={Taltech} onClick={navigateHome} alt="taltech logo"/>
        </div>

        <AuthenticatedTemplate>
          <div>
            <>
              <span className="welcome">
                {user && <b>{user.name}</b>}
              </span>
              <Button
                primary
                size="small"
                onClick={handleLogoutRedirect}
                label="Logout"
              />
            </>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <>
            <Button
              primary
              size="small"
              onClick={handleLoginRedirect}
              label="Login"
            />
          </>
        </UnauthenticatedTemplate>
      </div>
    </header>
  )
}
