import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { Home } from '../component/Home'
import { Overview } from '../component/Overview'
import { Document } from '../component/Document'
import { NavDrawer } from '../component/NavDrawer'
import { User } from '../component/User'
import Notifications from '@mui/icons-material/Notifications'

export const AppRoutes = () => {
  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: 'navdrawer', element: <NavDrawer /> },
    { path: 'dashboard', element: <Overview /> },
    { path: 'document', element: <Document /> },
    { path: 'user', element: <User /> },
    { path: 'notifications', element: <Notifications /> },
    { path: '*', element: <Navigate to="/" /> }
  ])

  return routes
}
