import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import SummaryWidget from './SummaryWidget'
import Diversity1TwoToneIcon from '@mui/icons-material/Diversity1TwoTone'
import DatasetIcon from '@mui/icons-material/Dataset'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { PieChart, LineChart } from '@mui/x-charts'
import Typography from '@mui/material/Typography'
import { AuthenticatedTemplate } from '@azure/msal-react'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

export const Overview = () => {
  return (
    <div className="card">
      <AuthenticatedTemplate>
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid xs={12} sm={6} md={3}>
              <SummaryWidget
                title="Total Visitors"
                total={100}
                color="success"
                icon={<Diversity1TwoToneIcon sx={{ color: '#FF0000' }}/>}
              />
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <SummaryWidget
                title="Datasets"
                total={1}
                color="success"
                icon={<DatasetIcon sx={{ color: '#4CBB17' }}/>}
              />
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <SummaryWidget
                title="Total reports"
                total={1}
                color="success"
                icon={<PictureAsPdfIcon sx={{ color: '#fd7e14' }}/>}
              />
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <SummaryWidget
                title="Total downloads"
                total={2}
                color="success"
                icon={<FileDownloadIcon sx={{ color: '#4169E1' }}/>}
              />
            </Grid>
          </Grid>
        </Container>
        <Grid container spacing={2} alignItems="center" sx={{ b: 2, t: 6, p: 2 }}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ t: 2 }}>
              Website statistics
            </Typography>
            <LineChart
              xAxis={[
                {
                  data: [1, 2, 3, 4, 5, 6, 7],
                  label: 'February 2024'
                }
              ]}
              series={[
                {
                  data: [26, 567, 2, 20, 30, 303, 23],
                  label: 'Number of daily visitors',
                  color: '#43a6b8',
                  area: true
                }
              ]}
              width={400}
              height={240}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h7" sx={{ t: 2 }}>
              Visits by location
            </Typography>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 50, label: 'Europe', color: '#e4067e' },
                    {
                      id: 1,
                      value: 5,
                      label: 'North America',
                      color: '#c9cbd8'
                    },
                    {
                      id: 2,
                      value: 2,
                      label: 'South America',
                      color: '#752e3b'
                    },
                    { id: 3, value: 10, label: 'Africa', color: '#ffc75a' },
                    { id: 4, value: 15, label: 'Asia', color: '#62bb46' },
                    { id: 5, value: 5, label: 'Australia', color: '#1b4e84' },
                    { id: 6, value: 1, label: 'Antarctica', color: '#20c997' }
                  ]
                }
              ]}
              width={500}
              height={250}
            />
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </div>
  )
}
